<template>
    <div class="scrlg_dtl_section" v-if="is_data_ready">
        <div class="current_league_score_table" v-for="tournament in tables" :key="tournament.id">
            <span class="competition_header" v-if="tournament.rugbyTournamentTable.length">{{tournament.name}}</span>
            <table class="league_score_table" v-if="tournament.rugbyTournamentTable.length">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Team</th>
                    <th class="current_league_mobile_hidden">GP</th>
                    <th class="current_league_mobile_hidden">Won</th>
                    <th class="current_league_mobile_hidden">Draw</th>
                    <th class="current_league_mobile_hidden">Lost</th>
                    <th class="current_league_mobile_hidden">GF</th>
                    <th class="current_league_mobile_hidden">GA</th>
                    <th class="current_league_mobile_hidden">GD</th>
                    <th>Points</th>
<!--                    <th>Last Five</th>-->
                </tr>
                </thead>
                <tbody>
                <tr v-for="table in tournament.rugbyTournamentTable" :key="table.id">
                    <td :style="{'border-left': 'solid 6px'+table.color}" v-if="table.color">
                        {{table.place}}
                    </td>
                    <td v-else>
                        {{table.place}}
                    </td>
                    <td >
                        <div class="current_league_score_table_teamlogo" v-if="table.team">
                            <img :src="$storageURL+'images/rugby/'+table.team.id+'_90x90.webp'" alt="team logo">
                            {{table.team.name}}
                        </div>
                    </td>
                    <td class="current_league_mobile_hidden">
                        {{table.played}}
                    </td>
                    <td class="current_league_mobile_hidden">
                        {{table.won}}
                    </td>
                    <td class="current_league_mobile_hidden">
                        {{table.draws}}
                    </td>
                    <td class="current_league_mobile_hidden">
                        {{table.lost}}
                    </td>
                    <td class="current_league_mobile_hidden">
                        {{table.scored}}
                    </td>
                    <td class="current_league_mobile_hidden">
                        {{table.conceded}}
                    </td>
                    <td class="current_league_mobile_hidden">
                        {{table.difference}}
                    </td>
                    <td>
                        {{table.points}}
                    </td>
<!--                    <td>-->
<!--                        <div class="last_five_status_table"><span class="win_match">-->
<!--                            W-->
<!--                        </span> <span class="lose_match">-->
<!--                            L-->
<!--                        </span> <span class="draw_match">-->
<!--                            D-->
<!--                        </span> <span class="lose_match">-->
<!--                            L-->
<!--                        </span> <span class="draw_match">-->
<!--                            D-->
<!--                        </span></div>-->
<!--                    </td>-->
                </tr>
                </tbody>
            </table>
<!--            <div class="league_fivestatus_color_info"><h4>Last 5 Macthes</h4>-->
<!--                <ul>-->
<!--                    <li><span style="background: rgb(19, 165, 56);"></span>-->
<!--                        Win-->
<!--                    </li>-->
<!--                    <li><span style="background: rgb(216, 0, 39);"></span>-->
<!--                        Loss-->
<!--                    </li>-->
<!--                    <li><span style="background: rgb(243, 146, 0);"></span>-->
<!--                        Draw-->
<!--                    </li>-->
<!--                    <li><span style="background: rgb(255, 255, 255); border: 1px solid rgb(136, 136, 136);"></span>-->
<!--                        Not Played-->
<!--                    </li>-->
<!--                </ul>-->
<!--            </div>-->
<!--            <div class="current_league_score_btn">&lt;!&ndash;&ndash;&gt; <a class="" href="javascript:;">Show Less</a></div>-->
        </div>
    </div>
</template>

<script>

    export default {
        name: 'RugbyAllTables',
        metaInfo() {
            let ss = this.$store.state.siteSetting;
            let title = this.$getSafe(() => ss.rugby_match_table_meta_title);
            let description = this.$getSafe(() => ss.rugby_match_table_meta_description);
            let keywords = this.$getSafe(() => ss.rugby_match_table_meta_keywords);
            return {
                title: title,
                description: description,
                meta: this.$getMetaTags(title, description, keywords),
                link: [
                    {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}],
            };
        },
        data() {
            return {
                tables: []
            }
        },
        components: {},
        mounted() {
            this.getAllTables();
        },
        serverPrefetch() {
            return this.getAllTables();
        },
        watch: {
            $route(to, from) {
                this.getAllTables();
            }
        },
        methods: {
            getAllTables() {
                this.is_data_ready = false;
                var params = '';
                if(this.$route.params.season != 'All') {
                    params = {season: this.$route.params.season};
                }
                return this.$axios.get(this.$RUGBY_ALL_TABLES, {params})
                    .then(response => {
                        if (response.data) {
                            this.tables = response.data.tables;
                            this.is_data_ready = true
                        }
                    })
                    .catch(error => {
                    })
            },
        }
    }
</script>
